import React from "react";
import styled from "styled-components";
import { PageWrapper } from "../App";
import { FlexWrapper } from "../components/FlexWrapper";

import prizes from "../img/2024-prizes.png";
const Prizes2 = () => {
  const [windowSize, setWindowSize] = React.useState([
    window.innerWidth,
    window.innerHeight,
  ]);

  React.useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize([window.innerWidth, window.innerHeight]);
    };

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  });
  return (
    <FlexWrapper
      type="column"
      width="100vw"
      style={{ position: "relative" }}
      id="prizes"
    >
      <div
        style={{
          width: "100vw",
          backgroundColor: "#0052a1",
          marginTop: "32px",
        }}
      >
        <PageWrapper>
          <SectionTitle>Balvas</SectionTitle>
        </PageWrapper>
      </div>

      <PageWrapper style={{ position: "relative" }}>
        <Wrapper>
          <FlexWrapper>
            <HeaderMainImage src={prizes} />
          </FlexWrapper>
        </Wrapper>
      </PageWrapper>
    </FlexWrapper>
  );
};

export default Prizes2;

const Wrapper = styled.div`
  padding: 8px 0;
  margin: 0 auto;
  margin-top: 32px;
`;

const SectionTitle = styled.p`
  font-family: LemonMilkProRegular;
  text-transform: uppercase;
  color: white;
  text-align: center;
  font-size: 48px;
  font-weight: 400;
  margin: 8px 0;
`;

const HeaderMainImage = styled.img`
  width: 100%;
`;
