import React from "react";
import styled from "styled-components";
import { openInNewTab } from "../Admin/Admin.component";
import { PageWrapper } from "../App";
import { FlexWrapper } from "../components/FlexWrapper";
import rules from "../pdf/noteikumi-2024.pdf";
const Header = () => {
  const [windowSize, setWindowSize] = React.useState([
    window.innerWidth,
    window.innerHeight,
  ]);

  React.useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize([window.innerWidth, window.innerHeight]);
    };

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  });

  const scrollTo = (id: string) => {
    const section = document.querySelector(id);
    section!.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  };
  return (
    <div
      style={{
        width: "100vw",
        backgroundColor: "#0052a1",
      }}
    >
      <PageWrapper>
        <Wrapper>
          <FlexWrapper type="column" width="100%">
            <FlexWrapper
              type={"row"}
              width="100%"
              justifyContent="space-between"
            >
              <NavText onClick={() => scrollTo("#register")}>
                reģistrēties
              </NavText>
              <NavText onClick={() => scrollTo("#prizes")}>balvas</NavText>
              <NavText onClick={() => scrollTo("#winners")}>laimētāji</NavText>
              <NavText onClick={() => openInNewTab(rules)}>noteikumi</NavText>
            </FlexWrapper>
          </FlexWrapper>
        </Wrapper>
      </PageWrapper>
    </div>
  );
};

export default Header;

const Wrapper = styled.div`
  background-color: #0052a1;
  padding: 24px 0;
  padding-bottom: 4px;
  overflow: hidden;
`;

const NavText = styled.p`
  text-transform: uppercase;
  font-family: PFDinTextCompProRegular;
  font-size: 24px;
  color: #ffe500;
  margin: 0;
  cursor: pointer;
`;
