import React from "react";
import styled from "styled-components";

import main from "../img/2024-main.png";
const Prizes = () => {
  const [windowSize, setWindowSize] = React.useState([
    window.innerWidth,
    window.innerHeight,
  ]);

  React.useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize([window.innerWidth, window.innerHeight]);
    };

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  });

  return (
    <div
      style={{
        width: "100vw",
        position: "relative",
      }}
    >
      <Wrapper>
        <MainPrizesImage
          src={main}
          right={windowSize[0] > 768 ? "50px" : "0px"}
        />
      </Wrapper>
      <div
        style={{
          width: "100vw",
          height: windowSize[0] > 768 ? "78px" : "57px",
          backgroundColor: "#0052a1",
          position: "absolute",
          left: "0",
          bottom: "0px",
        }}
      />
    </div>
  );
};

export default Prizes;

const Wrapper = styled.div`
  padding: 8px 0;
  max-width: 868px;
  margin: 0 auto;
`;

const MainPrizesImage = styled.img<{ right: string }>`
  width: 100%;
  position: relative;
  z-index: 999999;
  right: ${(p) => p.right};
`;
