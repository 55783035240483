import { ComponentPropsWithoutRef, ElementType, ReactNode } from "react";
import styled from "styled-components/macro";

type Props<T extends ElementType> = {
  renderAs?: keyof JSX.IntrinsicElements;
  children?: ReactNode;
  type?: "row" | "column";
  alignItems?: string;
  justifyContent?: string;
  gap?: string;
  height?: string;
  width?: string;
} & ComponentPropsWithoutRef<T>;

export const FlexWrapper = <T extends ElementType = "div">({
  renderAs,
  children,
  type = "row",
  alignItems = "center",
  justifyContent = "center",
  gap = "none",
  height = "fit-content",
  width = "auto",
  ...rest
}: Props<T>): JSX.Element => {
  return (
    <StyledFlexGroupRow
      as={renderAs}
      {...rest}
      type={type}
      alignItems={alignItems}
      justifyContent={justifyContent}
      gap={gap}
      height={height}
      width={width}
    >
      {children}
    </StyledFlexGroupRow>
  );
};

const StyledFlexGroupRow = styled.div<{
  type: "row" | "column";
  alignItems: string;
  justifyContent: string;
  gap: string;
  height: string;
  width: string;
}>`
  display: flex;
  flex-direction: ${(p) => p.type};
  align-items: ${(p) => p.alignItems};
  justify-content: ${(p) => p.justifyContent};
  gap: ${(p) => p.gap};
  height: ${(p) => p.height};
  width: ${(p) => p.width};
`;
