import { ComponentPropsWithoutRef, ElementType, ReactNode } from "react";
import styled from "styled-components/macro";

type Props<T extends ElementType> = {
  renderAs?: keyof JSX.IntrinsicElements;
  children?: ReactNode;
  columns?: number;
  type?: "row" | "column" | "row-reverse" | "column-reverse";
  alignItems?: string;
  justifyContent?: string;
  gap?: string;
  height?: string;
  width?: string;
  columnGap?: string;
  rowGap?: string;
} & ComponentPropsWithoutRef<T>;

export const GridColumns = <T extends ElementType = "div">({
  renderAs,
  children,
  columns = 2,
  type = "row",
  alignItems = "center",
  justifyContent = "center",
  gap = "32px",
  height = "fit-content",
  width = "auto",
  columnGap = gap,
  rowGap = gap,
  ...rest
}: Props<T>): JSX.Element => {
  return (
    <StyledGridColumns
      columns={columns}
      as={renderAs}
      {...rest}
      type={type}
      alignItems={alignItems}
      justifyContent={justifyContent}
      gap={gap}
      height={height}
      width={width}
      rowGap={rowGap}
      columnGap={columnGap}
    >
      {children}
    </StyledGridColumns>
  );
};

const StyledGridColumns = styled.div<{
  columns: number;
  type: "row" | "column" | "row-reverse" | "column-reverse";
  alignItems: string;
  justifyContent: string;
  gap: string;
  height: string;
  width: string;
  rowGap: string;
  columnGap: string;
}>`
  display: grid;
  grid-template-columns: repeat(${(props) => props.columns || "2"}, 1fr);

  flex-direction: ${(p) => p.type};
  align-items: ${(p) => p.alignItems};
  justify-content: ${(p) => p.justifyContent};
  grid-gap: ${(p) => p.gap};
  height: ${(p) => p.height};
  width: ${(p) => p.width};

  justify-content: stretch;

  column-gap: ${(p) => p.columnGap};
  row-gap: ${(p) => p.rowGap};
`;
