import axios from "axios";
import React from "react";
import { useCookies } from "react-cookie";
import { API_BASE_URL } from "./config";

const checkStatus = async () => {
  try {
    await axios.get(`${API_BASE_URL}/admin/status`, {
      withCredentials: true,
    });
  } catch (err: any) {
    console.error("ERROR: admin.status.get", err);
  }
};

export default function Admin() {
  const [cookies, , removeCookie] = useCookies(["api_key"]);

  React.useEffect(() => {
    checkStatus();
  }, []);

  if (!cookies.api_key) {
    return <Login />;
  }

  return (
    <div className="p-4 max-w-[768px] mx-auto flex flex-col gap-4 text-blue">
      <nav className="flex flex-row justify-between items-center">
        <p className="text-2xl text-red font-LemonMilkProRegular">
          EVEREST - 2024
        </p>
        <button className="bg-blue text-white py-2 px-4 disabled:opacity-50">
          <span
            onClick={() => removeCookie("api_key")}
            className="font-LemonMilkProRegular"
          >
            Iziet
          </span>
        </button>
      </nav>
      <Winners />
    </div>
  );
}

const Winners = () => {
  const [data, setData] = React.useState({
    samsung: { title: "SAMSUNG GALAXY S24", winners: [] },
    gopro: { title: "SPORTA KAMERU GOPRO HERO12", winners: [] },
    gramin: {
      title: "GARMIN FENIX 7 PRO",
      winners: [],
    },
    sportland: {
      title: "SPORTLAND DAVĀVANU KARTI 100 EUR VĒRTĪBĀ",
      winners: [],
    },
    six_pack: { title: "EVEREST UP 6-PAKU", winners: [] },
  });

  const loadData = async () => {
    try {
      const { data: res } = await axios.get(
        `${API_BASE_URL}/everest/winner/list`
      );

      setData({
        samsung: { title: "SAMSUNG GALAXY S24", winners: res.data.samsung },
        gopro: {
          title: "SPORTA KAMERU GOPRO HERO12",
          winners: res.data.gopro,
        },
        gramin: {
          title: "GARMIN FENIX 7 PRO",
          winners: res.data.gramin,
        },
        sportland: {
          title: "SPORTLAND DAVĀVANU KARTI 100 EUR VĒRTĪBĀ",
          winners: res.data.sportland,
        },
        six_pack: { title: "EVEREST UP 6-PAKU", winners: res.data.six_pack },
      });
    } catch (err: any) {
      console.error("ERROR: loadData:", err);
    }
  };

  React.useEffect(() => {
    loadData();
  }, []);

  const deleteWinner = async (id: string) => {
    try {
      await axios.delete(`${API_BASE_URL}/everest/winner/${id}`, {
        withCredentials: true,
      });
    } catch (err: any) {
      console.error("ERROR: deleteWinner:", err);
      alert("Kaut kas nogāja greizi mēģinot izdzēst ierakstu, mēģini vēlreiz!");
    }
    loadData();
  };

  return (
    <div className="flex flex-col gap-12 py-8">
      {Object.entries(data).map(([key, item]) => (
        <div className="flex flex-col gap-4 ">
          <p className="uppercase font-LemonMilkProRegular text-blue text-2xl pb-2">
            {item.title}
          </p>

          {item.winners.length > 0 && (
            <div>
              {item.winners.map((winner: any) => (
                <div className="flex flex-row gap-2 items-center">
                  <p className="capitalize font-LemonMilkProRegular">
                    {winner.name} {`[ID: ${winner.id}]`}
                  </p>
                  <span
                    onClick={() => deleteWinner(winner.id)}
                    className="text-sm cursor-pointer bg-blue text-white font-bold rounded-full px-2 text-center"
                  >
                    dzēst
                  </span>
                </div>
              ))}
            </div>
          )}

          <AddWinner prize={key} refetchWinners={loadData} />
        </div>
      ))}
    </div>
  );
};

const AddWinner = ({
  prize,
  refetchWinners,
}: {
  prize: string;
  refetchWinners: () => void;
}) => {
  const [name, setName] = React.useState<string>("");
  const [loading, setLoading] = React.useState<boolean>(false);

  const add = async () => {
    setLoading(true);
    try {
      await axios.post(
        `${API_BASE_URL}/everest/winner`,
        { name, prize },
        { withCredentials: true }
      );
      setName("");
    } catch (err: any) {
      console.error("ERROR: addWinner:", err);
      alert("Kaut kas nogāja greizi pievienojot ierakstu, mēģini vēlreiz!");
    }
    refetchWinners();
    setLoading(false);
  };

  return (
    <div className="flex flex-row gap-2">
      <input
        placeholder="Vārds, kurš rādīsies lapā"
        value={name}
        onChange={(e) => setName(e.target.value)}
        className="w-[300px] px-4 text-black"
      />
      <button
        onClick={add}
        disabled={loading || name.length < 2}
        className="bg-blue text-white py-2 px-4 disabled:opacity-50"
      >
        <span>Pievienot</span>
      </button>
    </div>
  );
};

const Login = () => {
  const [, setCookie] = useCookies(["api_key"]);

  const [password, setPassword] = React.useState<string>("");

  const onSubmit = async () => {
    try {
      const { data } = await axios.post(
        `${API_BASE_URL}/admin/login`,
        {
          password,
        },
        { withCredentials: true }
      );

      checkStatus();

      setCookie("api_key", data.data.api_key);
    } catch (err: any) {
      console.error("ERROR: Login:", err);
      alert("Kaut kas nogāja greizi autorizējoties, mēģini vēlreiz!");
    }
  };

  return (
    <div className="flex h-screen justify-center items-center">
      <div>
        <input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Parole"
          className="py-2 px-4 text-black"
        />
        <button
          onClick={onSubmit}
          disabled={password.length < 4}
          className="bg-blue text-black py-2 px-4 disabled:opacity-50"
        >
          <span className="text-white">Ienākt</span>
        </button>
      </div>
    </div>
  );
};
