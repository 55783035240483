import { createGlobalStyle } from "styled-components";

import LemonMilkProBold from "./fonts/LemonMilkProBold.otf";
import LemonMilkProMedium from "./fonts/LemonMilkProMedium.otf";
import LemonMilkProRegular from "./fonts/LemonMilkProRegular.otf";
import LemonMilkProRegularItalic from "./fonts/LemonMilkProRegularItalic.otf";

import MilkLight300 from "./fonts/MilkLight300.otf";

import PFDinTextCompProLight from "./fonts/PFDinTextCompProLight.ttf";
import PFDinTextCompProRegular from "./fonts/PFDinTextCompProRegular.ttf";
import PFDinTextCompProXBlack from "./fonts/PFDinTextCompProXBlack.ttf";

import ProximaNovaBold from "./fonts/ProximaNovaBold.otf";

/* istanbul ignore next */
export const GlobalStyle = createGlobalStyle`

  html,
  body {
    height: 100%;
    width: 100vw;
    line-height: 1.5;
    margin: 0;
    padding: 0;
	  background-color: #ffe500;

  }

  body {
    // background-color: #0052a1;
  }

  input, select, button, .span__error {
    font-family: inherit;
    font-size: inherit;


  }



  .span__error {
    font-family: LemonMilkProRegular;
    font-size: 8px;
  }


  @font-face {
    font-family: 'LemonMilkProBold';
    src: url(${LemonMilkProBold}) format('truetype');
    font-weight: 600;
    font-style: normal;
    font-display: auto;
  }

  @font-face {
    font-family: 'LemonMilkProMedium';
    src: url(${LemonMilkProMedium}) format('truetype');
    font-weight: 400;
    font-style: normal;
    font-display: auto;
  }

  @font-face {
    font-family: 'LemonMilkProRegular';
    src: url(${LemonMilkProRegular}) format('truetype');
    font-weight: 400;
    font-style: normal;
    font-display: auto;
  }
  @font-face {
    font-family: 'LemonMilkProRegularItalic';
    src: url(${LemonMilkProRegularItalic}) format('truetype');
    font-weight: 400;
    font-style: normal;
    font-display: auto;
  }
  @font-face {
    font-family: 'MilkLight300';
    src: url(${MilkLight300}) format('truetype');
    font-weight: 400;
    font-style: normal;
    font-display: auto;
  }
  @font-face {
    font-family: 'PFDinTextCompProRegular';
    src: url(${PFDinTextCompProRegular}) format('truetype');
    font-weight: 400;
    font-style: normal;
    font-display: auto;
  }
  @font-face {
    font-family: 'PFDinTextCompProXBlack';
    src: url(${PFDinTextCompProXBlack}) format('truetype');
    font-weight: 400;
    font-style: normal;
    font-display: auto;
  }
  @font-face {
    font-family: 'PFDinTextCompProLight';
    src: url(${PFDinTextCompProLight}) format('truetype');
    font-weight: 400;
    font-style: normal;
    font-display: auto;
  }
  @font-face {
    font-family: 'ProximaNovaBold';
    src: url(${ProximaNovaBold}) format('truetype');
    font-weight: 400;
    font-style: normal;
    font-display: auto;
  }

`;
