import axios from "axios";
import { Field, Form, Formik } from "formik";
import moment from "moment";
import React from "react";
import styled from "styled-components";
import * as Yup from "yup";
import { PageWrapper } from "../App";
import { FlexWrapper } from "../components/FlexWrapper";
import { GridColumns } from "../components/GridColumns";
import { API_BASE_URL } from "../config";
import rules from "../pdf/noteikumi-2024.pdf";

interface Values {
  FirstName: string;
  LastName: string;
  // Mail: string;
  Phone: string;
  // Age: string;
  LotteryRules: boolean;
  CampaignRegulations: boolean;
  ReceiptNumber: string;
}

const validationSchema = Yup.object().shape({
  FirstName: Yup.string().required("*Vārds ir nepieciešams"),
  LastName: Yup.string().required("*Uzvārds ir nepieciešams"),
  // Mail: Yup.string().email("Ievadiet pareizu e-pastu"),
  Phone: Yup.string()
    .length(8, "Telefona nummurā jābūt 8 skaitļiem")
    .required("*Talrunis ir nepieciešams"),
  // Age: Yup.number()
  //   .min(18, "Vecumam jābūt virs 18 gadiem")
  //   .required("*Vecums ir nepieciešams"),
  LotteryRules: Yup.boolean()
    .isTrue("*Kampaņas noteikumiem ir jāpiekrīt")
    .required("*Nepieciešams piekrist noteikumiem"),
  CampaignRegulations: Yup.boolean()
    .isTrue("*Datu apstrādei ir jāpiekrīt")
    .required("*Nepieciešams apstiprināt datu apstrādi"),
  // Subscribe: Yup.boolean().required("*Nepieciešams apstiprināt noteikumus"),
  ReceiptNumber: Yup.string().required("*Čeka numurs ir nepieciešams"),
});
// console.log(moment.now(), moment("21.04.2023", "DD.MM.YYYY").valueOf());

const Register = () => {
  const [statusMessage, setStatusMessage] = React.useState("");
  const [err, setErr] = React.useState(false);

  const [ip, setIP] = React.useState("");

  const disabled =
    moment.now() <= moment("01.06.2024", "DD.MM.YYYY").valueOf() ||
    moment.now() >= moment("01.08.2024", "DD.MM.YYYY").valueOf();

  //creating function to load ip address from the API
  const getData = async () => {
    const res = await axios.get("https://geolocation-db.com/json/");
    console.log(res.data);
    setIP(res.data.IPv4);
  };

  React.useEffect(() => {
    getData();
  }, []);

  const [windowSize, setWindowSize] = React.useState([
    window.innerWidth,
    window.innerHeight,
  ]);

  React.useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize([window.innerWidth, window.innerHeight]);
    };

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  });
  return (
    <FlexWrapper
      type='column'
      width='100vw'
      style={{ position: "relative" }}
      id='register'
    >
      <div
        style={{
          width: "100vw",
          backgroundColor: "#0052a1",
        }}
      >
        <PageWrapper>
          <SectionTitle>reģistrēties</SectionTitle>
        </PageWrapper>
      </div>

      <PageWrapper style={{ position: "relative" }}>
        <Wrapper>
          <div className='div__register-box'>
            <Formik
              initialValues={{
                FirstName: "",
                LastName: "",
                // Mail: "",
                Phone: "",
                // Age: "",
                CampaignRegulations: false,
                // Subscribe: false,
                LotteryRules: false,
                ReceiptNumber: "",
              }}
              validationSchema={validationSchema}
              onSubmit={(values: Values, { resetForm }) => {
                try {
                  axios
                    .post(`${API_BASE_URL}/everest/register`, {
                      first_name: values.FirstName,
                      last_name: values.LastName,
                      // Mail: values.Mail,
                      phone_number: values.Phone,
                      // Age: values.Age,
                      campaign_regulations: values.CampaignRegulations,
                      // campaign: type,
                      ip: ip,
                      receipt_number: values.ReceiptNumber,
                    })
                    .then((res: any) => {
                      alert("Paldies, reģistrācija veiksmīga!");
                      resetForm();
                    })
                    .catch((err) => {
                      alert(
                        `Kaut kas nogāja greizi, mēģini vēlreiz. ${err?.response?.data?.data?.message}`
                      );
                    });
                } catch (error: any) {
                  setErr(true);
                  setStatusMessage(error.message);
                  resetForm();
                }
              }}
            >
              {({ errors, touched }: { errors: any; touched: any }) => (
                <Form>
                  <FlexWrapper
                    type='column'
                    alignItems='flex-start'
                    justifyContent='flex-start'
                    width='100%'
                  >
                    <GridColumns
                      columnGap='8px'
                      columns={windowSize[0] < 768 ? 1 : 2}
                    >
                      <FlexWrapper
                        type='column'
                        alignItems='flex-start'
                        justifyContent='flex-start'
                      >
                        <label htmlFor='FirstName'>Vārds</label>
                        <Field id='FirstName' name='FirstName' type='text' />
                        {touched.FirstName && errors.FirstName ? (
                          <ErrorText className='span__error'>
                            {errors.FirstName}
                          </ErrorText>
                        ) : null}
                      </FlexWrapper>

                      <FlexWrapper
                        type='column'
                        alignItems='flex-start'
                        justifyContent='flex-start'
                      >
                        <label htmlFor='LastName'>Uzvārds</label>
                        <Field id='LastName' name='LastName' type='text' />
                        {touched.LastName && errors.LastName ? (
                          <ErrorText className='span__error'>
                            {errors.LastName}
                          </ErrorText>
                        ) : null}
                      </FlexWrapper>

                      {/* <FlexWrapper
                        type="row"
                        alignItems="flex-start"
                        justifyContent="space-between"
                        width="100%"
                      > */}
                      {/* <FlexWrapper
                          type="column"
                          alignItems="flex-start"
                          justifyContent="flex-start"
                        >
                          <label htmlFor="Age">Vecums</label>
                          <Field
                            id="Age"
                            name="Age"
                            type="number"
                            style={{ width: "80px" }}
                          />
                          {touched.Age && errors.Age ? (
                            <ErrorText className="span__error">
                              {errors.Age}
                            </ErrorText>
                          ) : null}
                        </FlexWrapper> */}
                      <FlexWrapper
                        type='column'
                        alignItems='flex-start'
                        justifyContent='flex-start'
                      >
                        <label htmlFor='Phone'>Tālrunis</label>
                        <Field
                          id='Phone'
                          name='Phone'
                          type='text'
                          // style={{ width: "190px" }}
                        />
                        {touched.Phone && errors.Phone ? (
                          <ErrorText className='span__error'>
                            {errors.Phone}
                          </ErrorText>
                        ) : null}
                      </FlexWrapper>
                      {/* </FlexWrapper> */}

                      {/* <FlexWrapper
                        type="column"
                        alignItems="flex-start"
                        justifyContent="flex-start"
                      >
                        <label htmlFor="Mail">E-pasts</label>
                        <Field id="Mail" name="Mail" type="email" />
                        {touched.Mail && errors.Mail ? (
                          <ErrorText className="span__error">
                            {errors.Mail}
                          </ErrorText>
                        ) : null}
                      </FlexWrapper> */}
                      <FlexWrapper
                        type='column'
                        alignItems='flex-start'
                        justifyContent='flex-start'
                      >
                        <label htmlFor='ReceiptNumber'>Čeka numurs</label>
                        <Field
                          id='ReceiptNumber'
                          name='ReceiptNumber'
                          type='text'
                        />
                        {touched.ReceiptNumber && errors.ReceiptNumber ? (
                          <ErrorText className='span__error'>
                            {errors.ReceiptNumber}
                          </ErrorText>
                        ) : null}
                      </FlexWrapper>
                    </GridColumns>
                    <FlexWrapper
                      type='column'
                      alignItems='flex-start'
                      justifyContent='flex-start'
                      gap='8px'
                      style={{ marginTop: "12px" }}
                    >
                      <FlexWrapper
                        type='row'
                        justifyContent='flex-start'
                        alignItems='center'
                        width='100%'
                        gap='8px'
                      >
                        <FlexWrapper width='30px'>
                          <Field
                            id='LotteryRules'
                            name='LotteryRules'
                            type='checkbox'
                          />
                        </FlexWrapper>

                        <FlexWrapper
                          type='column'
                          justifyContent='flex-start'
                          alignItems='flex-start'
                        >
                          <label htmlFor='LotteryRules'>
                            Es piekrītu kampaņas{" "}
                            <a
                              href={rules}
                              target='_blank'
                              rel='noopener noreferrer'
                              style={{
                                color: "#0052a1",
                                textDecoration: "underline",
                              }}
                            >
                              noteikumiem
                            </a>
                          </label>
                          {touched.LotteryRules && errors.LotteryRules ? (
                            <ErrorText className='span__error'>
                              {errors.LotteryRules}
                            </ErrorText>
                          ) : null}
                        </FlexWrapper>
                      </FlexWrapper>

                      <FlexWrapper
                        type='row'
                        justifyContent='flex-start'
                        alignItems='flex-start'
                        width='100%'
                        gap='8px'
                      >
                        <FlexWrapper width='30px'>
                          <Field
                            id='CampaignRegulations'
                            name='CampaignRegulations'
                            type='checkbox'
                          />
                        </FlexWrapper>
                        <FlexWrapper
                          type='column'
                          justifyContent='flex-start'
                          alignItems='flex-start'
                        >
                          <label htmlFor='CampaignRegulations'>
                            Piekrītu datu apstrādes noteikumiem*
                          </label>
                          {touched.CampaignRegulations &&
                          errors.CampaignRegulations ? (
                            <ErrorText className='span__error'>
                              {errors.CampaignRegulations}
                            </ErrorText>
                          ) : null}

                          <span
                            className='span__accept-rules'
                            style={{
                              fontFamily: "PFDinTextCompProLight",
                              fontSize: "12px",
                            }}
                          >
                            <p className='text-sm'>
                              *Reģistrējot dalību loterijai, Tu piekrīti, ka SIA
                              “Visas Loterijas” veic šādu personas datu
                              apstrādi. Vārds, Uzvārds nepieciešams lai
                              nodrošinātu Jūsu identifikāciju laimesta gadījumā
                              un pārliecinātos par iesniegto datu pareizību.
                              Tālruņa numurs nepieciešams, lai varētu ar Tevi
                              sazināties gadījumā, ja būsi laimējis.
                              Dokumenta/čeka numurs nepieciešams, lai
                              indentificēta pirkuma veikšanu un atbilstību
                              loterijas noteikumiem. Datu subjektiem,
                              piedaloties šajā loterijā, ir visas GDPR noteiktās
                              tiesības. Vairāk info{" "}
                              <a
                                style={{ textDecoration: "underline" }}
                                href='https://www.cesualus.lv/personas-datu-aizsardzibas-politika/'
                                target='_blank'
                                rel='noopener noreferrer'
                              >
                                šeit
                              </a>
                              .
                            </p>
                          </span>
                        </FlexWrapper>
                      </FlexWrapper>
                    </FlexWrapper>
                    <SubmitButton type='submit' disabled={disabled}>
                      {disabled
                        ? "REĢISTRĀCIJA PIEEJAMA 1.JŪNIJĀ"
                        : "REĢISTRĒTIES"}
                    </SubmitButton>

                    {statusMessage !== "" && (
                      <FlexWrapper width='100%' style={{ marginTop: "32px" }}>
                        <span
                          className='span__error'
                          style={{
                            color: !err ? "green" : "white",
                            borderRadius: "5px",
                            padding: "10px 20px",
                            backgroundColor: err ? "red" : "white",
                            fontFamily: "LemonMilkProRegular",
                            margin: "0 auto",
                          }}
                        >
                          {statusMessage}
                        </span>
                      </FlexWrapper>
                    )}
                  </FlexWrapper>
                </Form>
              )}
            </Formik>
          </div>
        </Wrapper>
      </PageWrapper>
    </FlexWrapper>
  );
};

export default Register;

const Wrapper = styled.div`
  padding: 8px 0;
  margin: 0 auto;
  margin-top: 32px;
  input {
    height: 50px;
    outline: none;
    border: none;
    font-size: 30px;
    font-family: PFDinTextCompProRegular;
    padding: 0px 10px;
    width: 300px;
    border-radius: 0px;
  }

  input[type="checkbox"] {
    width: 30px;
    height: 30px;
  }

  label {
    font-family: ProximaNovaBold;
  }
`;

const SectionTitle = styled.p`
  font-family: LemonMilkProRegular;
  text-transform: uppercase;
  color: white;
  text-align: center;
  font-size: 48px;
  font-weight: 400;
  margin: 24px 0;
`;

const ErrorText = styled.span`
  color: #ffe500;
  font-size: 12px;
  background-color: #0052a1;
  // width: 100%;
  border-radius: 0px 0 12px 12px;
  font-family: Arial;

  padding: 8px 12px;
  box-sizing: border-box;
`;

const SubmitButton = styled.button`
  // height: 50px;
  outline: none;
  border: none;
  font-size: 30px;
  font-family: PFDinTextCompProRegular;
  padding: 10px 20px;
  min-width: 300px;
  border-radius: 0px;
  background-color: #0052a1;
  color: #ffe500;
  margin: 0 auto;
  margin-top: 32px;

  &:disabled {
    opacity: 0.5;
  }
`;
