import axios from "axios";
import React from "react";
import { API_BASE_URL } from "../config";

export default function WinnersNew() {
  const [data, setData] = React.useState({
    samsung: { title: "SAMSUNG GALAXY S24", winners: [] },
    gopro: { title: "SPORTA KAMERU GOPRO HERO12", winners: [] },
    gramin: {
      title: "GARMIN FENIX 7 PRO",
      winners: [],
    },
    sportland: {
      title: "SPORTLAND DAVĀVANU KARTI 100 EUR VĒRTĪBĀ",
      winners: [],
    },
    six_pack: { title: "EVEREST UP 6-PAKU", winners: [] },
  });

  const loadData = async () => {
    try {
      const { data: res } = await axios.get(
        `${API_BASE_URL}/everest/winner/list`
      );

      setData({
        samsung: { title: "SAMSUNG GALAXY S24", winners: res.data.samsung },
        gopro: {
          title: "SPORTA KAMERU GOPRO HERO12",
          winners: res.data.gopro,
        },
        gramin: {
          title: "GARMIN FENIX 7 PRO",
          winners: res.data.gramin,
        },
        sportland: {
          title: "SPORTLAND DAVĀVANU KARTI 100 EUR VĒRTĪBĀ",
          winners: res.data.sportland,
        },
        six_pack: { title: "EVEREST UP 6-PAKU", winners: res.data.six_pack },
      });
    } catch (err: any) {
      console.error("ERROR: loadData:", err);
    }
  };

  React.useEffect(() => {
    loadData();
  }, []);

  return (
    <div className="w-full">
      <div className="px-8 py-6 bg-blue w-full text-center">
        <p
          id="winners"
          className="uppercase max-w-[768px] mx-auto font-LemonMilkProRegular text-white text-5xl"
        >
          uzvarētāji
        </p>
      </div>

      <div className="max-w-[768px] px-8 mx-auto flex flex-col gap-12 py-8">
        {Object.values(data).map((item) => (
          <div className="text-center">
            <p className="uppercase font-LemonMilkProRegular text-blue text-2xl pb-2">
              {item.title} laimējuši:
            </p>
            {!item.winners.length ? (
              <p className="font-LemonMilkProRegular">
                Laimētāji vēl nav izvēlēti
              </p>
            ) : (
              <div>
                {item.winners.map((winner: any) => (
                  <p className="capitalize font-LemonMilkProRegular">
                    {winner.name}
                  </p>
                ))}
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}
