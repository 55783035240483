import { GlobalStyle, Wrapper } from "./Admin.styled";

import axios from "axios";
import { Field, Form, Formik } from "formik";
import _ from "lodash";
import React from "react";
import { useCookies } from "react-cookie";
import * as Yup from "yup";

import "react-confirm-alert/src/react-confirm-alert.css";

interface Values {
  username: string;
  password: string;
}

const validationSchema = Yup.object().shape({
  username: Yup.string().required("*nepieciešams"),
  password: Yup.string().required("*nepieciešams"),
});

export const openInNewTab = (url: string) => {
  window.open(url, "_blank", "noreferrer");
};

export const Admin = () => {
  const [statusMessage, setStatusMessage] = React.useState("");
  const [err, setErr] = React.useState(false);
  const [loggedIn, setLoggedIn] = React.useState(false);
  const [cookies, setCookie, deleteCookie] = useCookies(["token"]);
  const [winnerData, setWinnerData] = React.useState<any>(undefined);

  const [winnerFirstName, setWinnerFirstName] = React.useState<string>("");
  const [winnerLastName, setWinnerLastName] = React.useState<string>("");
  const [winnerCampaign, setWinnerCampaign] = React.useState<string>("");
  const [winnerPrize, setWinnerPrize] = React.useState<string>("");
  //   const [checkNumber, setCheckNumber] = React.useState<string>("");
  const [submitButton, setSubmitButton] = React.useState<boolean>(false);

  const fetch_data = async () => {
    await axios
      .get(`${window.location.origin}/winners`)
      .then((res: any) => {
        setWinnerData(res.data);
      })
      .catch((error) => {
        setWinnerData(undefined);
      });
  };

  React.useEffect(() => {
    fetch_data();
  }, []);

  React.useEffect(() => {
    axios
      .get(`${window.location.origin}/check-token`, {
        withCredentials: true,
      })
      .then((res: any) => {
        if (cookies.token) {
          setLoggedIn(true);
        } else {
          setLoggedIn(false);
        }
        setErr(false);
        setStatusMessage(res.data.message);
      })
      .catch((error: any) => {
        setLoggedIn(false);
        setErr(true);
        setStatusMessage(error.message);
      });
  }, [cookies]);

  const handle_delete = async (id_to_delete: number) => {
    await axios
      .post(
        `${window.location.origin}/remove-winner`,
        { id_to_delete },
        { withCredentials: true }
      )
      .then((res: any) => {
        setErr(false);
        setStatusMessage(res.data.message);
      })
      .catch((error) => {
        setErr(true);
        setStatusMessage(error.message);
      });
    await fetch_data();
    {
      await fetch_data();
    }
  };

  React.useEffect(() => {
    if (
      winnerFirstName &&
      winnerLastName &&
      winnerPrize &&
      winnerCampaign
      //   && checkNumber
    ) {
      setSubmitButton(true);
    } else {
      setSubmitButton(false);
    }
  }, [
    winnerFirstName,
    winnerLastName,
    winnerPrize,
    winnerCampaign,
    // checkNumber,
  ]);

  const handle_submit = async (e: any) => {
    e.preventDefault();
    if (
      winnerFirstName &&
      winnerLastName &&
      winnerPrize &&
      winnerCampaign
      //   && checkNumber
    ) {
      await axios
        .post(
          `${window.location.origin}/add-winner`,
          {
            first_name: winnerFirstName,
            last_name: winnerLastName,
            prize: winnerPrize,
            campaign: winnerCampaign,
            // check_number: checkNumber,
          },
          { withCredentials: true }
        )
        .then((res: any) => {
          setErr(false);
          setStatusMessage(res.data.message);
          setWinnerFirstName("");
          setWinnerLastName("");
          setWinnerPrize("");
          setWinnerCampaign("");
          // setCheckNumber("");
        })
        .catch((error) => {
          setErr(true);
          setStatusMessage(error.message);
        });
      await fetch_data();
    }
  };

  if (!loggedIn) {
    return (
      <Wrapper>
        <GlobalStyle />
        <Formik
          initialValues={{
            username: "",
            password: "",
          }}
          validationSchema={validationSchema}
          onSubmit={(values: Values, { resetForm }) => {
            try {
              axios
                .post(
                  `${window.location.origin}/login`,
                  { username: values.username, password: values.password },
                  { withCredentials: true }
                )
                .then((res: any) => {
                  if (res && res.data.status === "error") {
                    setErr(true);
                    setStatusMessage(res.data.message);
                    resetForm();
                    setLoggedIn(false);
                  } else {
                    setErr(false);
                    setStatusMessage(res.data.message);
                    resetForm();
                    setLoggedIn(true);
                  }
                })
                .catch((error) => {
                  resetForm();
                  setErr(true);
                  setStatusMessage(error.message);
                  setLoggedIn(false);
                });
            } catch (error: any) {
              setErr(true);
              setStatusMessage(error.message);
              resetForm();
              setLoggedIn(false);
            }
          }}
        >
          {({ errors, touched }: { errors: any; touched: any }) => (
            <Form>
              <div className="div__form-box">
                <div className="div__form-item">
                  <label htmlFor="username">lietotājvārds</label>
                  <br />
                  <Field id="username" name="username" type="text" />
                  {touched.username && errors.username ? (
                    <span className="span__error">{errors.username}</span>
                  ) : null}
                </div>
                <div className="div__form-item">
                  <label htmlFor="password">parole</label>
                  <br />
                  <Field id="password" name="password" type="password" />
                  {touched.password && errors.password ? (
                    <span className="span__error">{errors.password}</span>
                  ) : null}
                </div>
                <br />
                <button type="submit">ienākt</button>
                {statusMessage !== "" && (
                  <div
                    className="div__error-box"
                    style={{ background: "black" }}
                  >
                    <span
                      className="span__error"
                      style={{
                        color: !err ? "green" : "white",
                        borderRadius: "5px",
                        padding: "10px 20px",
                      }}
                    >
                      {statusMessage}
                    </span>
                  </div>
                )}
              </div>
            </Form>
          )}
        </Formik>
      </Wrapper>
    );
  } else {
    return (
      <Wrapper>
        <GlobalStyle />
        <h1>
          pievienot laimētāju{" "}
          <span
            style={{ color: "red", fontSize: "16", cursor: "pointer" }}
            onClick={() => {
              deleteCookie("token", {
                path: "/",
                domain: window.location.origin,
              });
              setLoggedIn(false);
            }}
          >
            (iziet)
          </span>
        </h1>

        <div style={{ display: "flex", justifyContent: "center" }}>
          <form
            onSubmit={(e) => handle_submit(e)}
            style={{ display: "flex", flexDirection: "column" }}
          >
            <input
              type="text"
              name="first_name"
              id="first_name"
              placeholder="vārds"
              value={winnerFirstName}
              onChange={(e) => {
                setWinnerFirstName(e.target.value);
              }}
            />
            <input
              type="text"
              name="last_name"
              id="last_name"
              placeholder="uzvārds"
              value={winnerLastName}
              onChange={(e) => {
                setWinnerLastName(e.target.value);
              }}
            />
            {/* <input
              type="text"
              name="check_number"
              id="check_number"
              placeholder="čeka numurs"
              value={checkNumber}
              onChange={(e) => {
                setCheckNumber(e.target.value);
              }}
            /> */}
            <select
              name="prize"
              id="prize"
              value={winnerPrize}
              onChange={(e) => {
                setWinnerPrize(e.target.value);
              }}
            >
              <option value="" disabled selected>
                balva
              </option>
              <option value="samsung">samsung</option>
              <option value="gopro">gopro</option>
              <option value="garmin">garmin</option>
              <option value="sportland">sportland</option>
              <option value="six_pack">6-paka</option>
            </select>
            <select
              name="campaign"
              id="campaign"
              value={winnerCampaign}
              onChange={(e) => {
                setWinnerCampaign(e.target.value);
              }}
            >
              <option value="" disabled>
                kampaņa
              </option>
              <option value="everest" id="everest">
                everest
              </option>
            </select>
            <button type="submit" disabled={submitButton ? false : true}>
              pievienot
            </button>
          </form>
        </div>

        {statusMessage !== "" && (
          <div
            className="div__error-box"
            style={{
              background: "black",
              padding: "20px 0px",
              border: "2px solid white",
            }}
          >
            <span
              className="span__error"
              style={{
                color: !err ? "green" : "white",
                borderRadius: "5px",
                padding: "10px 20px",
              }}
            >
              {statusMessage}
            </span>
          </div>
        )}

        <div style={{ padding: "50px 80px", background: "black" }}>
          {_.map(winnerData, (item, index) => {
            return (
              <>
                <div className="div__campaign">
                  <h1 style={{ textTransform: "uppercase" }}> {index}</h1>
                  {_.map(item, (item_, index) => {
                    return (
                      <ul className="div__prize">
                        <li>
                          {index} {item_.length} /{" "}
                          {(index === "samsung" && 2) ||
                            (index === "gopro" && 2) ||
                            (index === "garmin" && 4) ||
                            (index === "sportland" && 4) ||
                            (index === "six_pack" && 61)}
                        </li>
                        <ul style={{ display: "flex", flexWrap: "wrap" }}>
                          {_.map(item_, (_item, index) => {
                            return (
                              <ol
                                className="div__name"
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                {/* <span
                                  style={{
                                    fontSize: "16px",
                                    margin: "0px 5px 0px 0px",
                                  }}
                                >
                                  id{`"${_item.id}"`}
                                </span> */}
                                {_item.first_name} {_item.last_name}
                                {/* {_item.check_number ? (
                                  <span
                                    style={{ fontSize: "16px", color: "white" }}
                                  >
                                    {_item.check_number}
                                  </span>
                                ) : (
                                  <span
                                    style={{ fontSize: "16px", color: "white" }}
                                  >
                                    nav čeka numurs
                                  </span>
                                )}
                                ){" "} */}
                                <span
                                  style={{
                                    background: "#e5383b",
                                    fontSize: "14px",
                                    lineHeight: "0",
                                    borderRadius: "5px",
                                    margin: "0px 0px 0px 4px",
                                    outline: "none",
                                    border: "1px solid #000",
                                    color: "#e5383b",
                                    cursor: "pointer",
                                    padding: "0px",
                                  }}
                                  onClick={() => {
                                    handle_delete(_item.id);
                                  }}
                                >
                                  {"(dzēst)"}
                                </span>
                              </ol>
                            );
                          })}
                        </ul>
                      </ul>
                    );
                  })}
                </div>
                <br />
                <br />
              </>
            );
          })}
        </div>
      </Wrapper>
    );
  }
};
