import styled from "styled-components";
import { FlexWrapper } from "./components/FlexWrapper";
import Header from "./Header";
import Prizes from "./Prizes";
import Prizes2 from "./Prizes2";
import Register from "./Register";
import { GlobalStyle } from "./styles/global-styles";
import WinnersNew from "./Winners/new";

function App() {
  return (
    <FlexWrapper type="column" width="100vw">
      <GlobalStyle />
      <Header />
      <Prizes />
      <Register />
      <Prizes2 />
      <WinnersNew />
      <div
        className='w-full py-8 flex justify-center items-center italic'
        style={{ fontFamily: "Inter" }}
      >
        <a
          href='https://integrators.lv?utm_source=limpo.lv'
          target='_blank'
          rel='noreferrer'
          className='font-bold hover:text-blue-600 transition-colors'
        >
          Izstrādāja INTEGRATORS.lv
        </a>
      </div>
    </FlexWrapper>
  );
}

export default App;

export const PageWrapper = styled.div`
  max-width: 768px;
  width: 100%;
  margin: 0 auto;
  padding: 0 25px;
  box-sizing: border-box;

  transition: all 0.5s ease;
`;
