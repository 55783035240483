import styled from "styled-components";

import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
  body, #root {
    background: grey;
		color: black;
  }
`;

const size = {
  xs: "320px",
  md: "768px",
  sm: "960px",
  lg: "1200px",
};

const device = {
  xs: `(min-width: ${size.xs})`,
  md: `(min-width: ${size.md})`,
  sm: `(min-width: ${size.sm})`,
  lg: `(min-width: ${size.lg})`,
};

// export const Sample = styled.div`
// 	@media only screen and ${device.xs}{
// 	}
// 	@media only screen and ${device.sm}{
// 	}
// 	@media only screen and ${device.lg}{
// 	}
// `;

export const Wrapper = styled.div`
  margin: 50px 30px;
  font-family: PFDinTextCompPro-Regular;
  color: #fef100;
  font-size: 22px;

  select,
  input,
  button[type="submit"] {
    width: 300px;
    margin: 0px 0px 5px 0px;
    height: 50px;
    outline: none;
    border: none;
    font-size: 30px;
    font-family: PFDinTextCompPro-Regular;
    padding: 0px 10px;
    width: 300px;
    border-radius: 0px;
  }

  @media only screen and ${device.xs} {
  }
  @media only screen and ${device.sm} {
    margin: 90px 100px;
  }
  @media only screen and ${device.lg} {
  }
`;
